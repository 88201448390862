import useTranslation from 'next-translate/useTranslation'
import { Dispatch, FC, SetStateAction } from 'react'
import { Button, FlexboxGrid } from 'rsuite'
import { useRouter } from 'next/router'
import Link from 'next/link'

import { getProductName } from '../lib/text'
import { getFullPrice, getProductPriceObject } from '../lib/resources/price'
import { getSalesUnit } from '../lib/salesUnit'
import { Currency, DEFAULT_CURRENCY } from '../utils/constants'
import { getLargePreview, getMediumPreview, imageLoader } from '../utils/images'
import { formatPriceNumber } from '../utils/math'
import { Product, ProductQuantity } from '../utils/types/Product'
import { getPathName, getProductMoq, isFreeDelivery } from '../lib/product'
import DiscountIcon from './Icons/DiscountIcon'
import useUrls from '../services/useUrls'
import Avatar from './Avatar/Avatar'
import ProductEmptyImage from './SingleProduct/ProductEmptyImage'
import SupplierMovWarning from './Cart/SupplierMovWarning'
import { useCart } from './Cart/CartProvider'
import { getRemainingMovCount } from '../lib/cart'
import { getSupplierDeliveryDetails } from '../lib/service'
import { getCountryAndLocaleStrings } from '../utils/locales'
import { selectSupplierTotal } from './Cart/selectors'
import useProductWeightedPrice from '../services/useProductWeightedPrice'
import PriceRevealToolTip from './PriceRevealTooltip'
import { getCurrentUpcomingDiscount } from '../lib/resources/discount'
import { isProductInclude } from '../utils/types/guards/Order'

import styles from '../styles/ProductOrderInfo.module.less'

interface ProductOrderInfoProps {
  product: Product
  productQuantity: ProductQuantity[]
  totalQuantity: string | number
  isStockLimitReached: boolean
  title?: boolean
  isQuantityUnderMoq?: boolean
  setEditMode?: Dispatch<SetStateAction<boolean>>
  editMode?: boolean
  isOnShareCart?: boolean
  enableWarningBar?: boolean
  discountType?: 'bulk' | 'consumption' | 'custom'
}

const ProductOrderInfo: FC<ProductOrderInfoProps> = (props) => {
  const {
    product,
    productQuantity,
    totalQuantity,
    isStockLimitReached,
    title,
    isQuantityUnderMoq,
    setEditMode,
    editMode,
    isOnShareCart,
    enableWarningBar,
    discountType,
  } = props

  const { t } = useTranslation('modals')
  const { urlT } = useUrls()
  const { modifiedCart } = useCart()
  const { locale: countryAndLocale } = useRouter()

  const {
    weightedPriceDiscounted,
    totalWeightedPriceDiscounted,
    productPriceDiscounted,
  } = useProductWeightedPrice(product, Number(totalQuantity))

  const { country } = getCountryAndLocaleStrings(countryAndLocale)

  const cartProduct = modifiedCart?.included.filter(isProductInclude).find((productInclude) => (
    productInclude.product?.['product.id'] === product.id
  ))

  const currentSupplier = modifiedCart?.supplierMappedProducts.find(
    (supp) => supp.supplierCode === product.supplier[0]['supplier.code'],
  )

  const supplierTotal = modifiedCart && currentSupplier
    ? selectSupplierTotal(modifiedCart, currentSupplier) || 0
    : 0

  const {
    minimumOrderValue: mov,
  } = getSupplierDeliveryDetails(currentSupplier?.supplierInfo, country)
  const remainingMov = getRemainingMovCount(supplierTotal, mov)
  const shouldShowWarning = enableWarningBar && supplierTotal < mov

  const media = product?.media?.map((file) => ({
    original: imageLoader(getLargePreview(file)),
    thumbnail: imageLoader(getMediumPreview(file)),
    originalHeight: 500,
    thumbnailHeight: 100,
  }))
  const {
    currentDiscount,
  } = getCurrentUpcomingDiscount(product, Number(totalQuantity))
  const productName = getProductName(product)
  const salesUnit = getSalesUnit(product)
  const productPrice = getProductPriceObject(product)
  const currencyId = productPrice?.['price.currencyid']
  const currency = currencyId ? Currency[currencyId] : Currency[DEFAULT_CURRENCY]
  const totalPrice = getFullPrice(product, Number(totalQuantity))
  const bulkDiscount = currentDiscount
    ? totalPrice - Number(totalQuantity) * (Number(currentDiscount['price.value']) + Number(currentDiscount['price.rebate']))
    : 0
  const consumptionDiscount = Number(totalQuantity) * Number(cartProduct?.attributes['order.product.rebate'])
  const totalDiscount = formatPriceNumber(bulkDiscount + consumptionDiscount)
  const productMoq = getProductMoq(product)
  const supplier = product?.supplier[0]
  const hasFreeDelivery = product && isFreeDelivery(product, country, supplierTotal)

  return (
    <div
      data-testid={isQuantityUnderMoq ? 'under-moq-product-panel' : undefined}
      className={styles['order-info']}
    >
      {title && (
        <h5 className="margin-bottom-spacer-double">
          {isStockLimitReached ? t('Inquiry details') : t('Order breakdown')}
          :
        </h5>
      )}
      <FlexboxGrid
        align={isQuantityUnderMoq ? 'middle' : undefined}
        className={styles['order-info-container']}
      >
        <FlexboxGrid.Item className={`margin-right-spacer-double ${styles['product-logo']}`}>
          <Link href={urlT(getPathName(product, t))}>
            {media?.length ? (
              <Avatar
                size="lg"
                imageSrc={media?.[0]?.thumbnail}
                altName={media?.[0]?.original}
              />
            )
              : (
                <ProductEmptyImage height={50} width={50} />
              )}
          </Link>
        </FlexboxGrid.Item>
        <FlexboxGrid.Item className={`flex-grow-1 ${styles['product-info']}`}>
          <FlexboxGrid justify="space-between">
            <FlexboxGrid.Item colspan={16}>
              <Link href={urlT(getPathName(product, t))}>
                <h5 className="margin-zero">{`${product?.['product.code']}, ${productName}`}</h5>
              </Link>

              {isQuantityUnderMoq && (
                <p className="margin-top-spacer">
                  {`${t('products:Minimum order')}: ${productMoq} ${salesUnit}`}
                </p>
              )}

              {!isQuantityUnderMoq && productQuantity.length !== 1
                && productQuantity
                  .filter((ele) => ele.quantity !== 0 && ele.quantity !== '0')
                  .map((item) => (
                    <div key={item.id}>
                      {`${item.label}: ${item.quantity} ${t(
                        `products:${salesUnit}`,
                        { count: Number(item.quantity) },
                      )}`}
                    </div>
                  ))}
            </FlexboxGrid.Item>

            {isQuantityUnderMoq && !isOnShareCart && (
              <Button
                disabled={editMode}
                onClick={() => setEditMode && setEditMode(true)}
                appearance="ghost"
                className={styles['modify-quantity-button']}
              >
                {t('cart:Modify quantity')}
              </Button>
            )}

            {!isQuantityUnderMoq && discountType === 'bulk' && (
              <FlexboxGrid.Item>
                <FlexboxGrid align="middle">
                  <DiscountIcon />
                  <b className={styles['discount-text']}>
                    {t('products:Bulk discounts')}
                  </b>
                </FlexboxGrid>
              </FlexboxGrid.Item>
            )}
          </FlexboxGrid>

          {!isQuantityUnderMoq && (
            <FlexboxGrid justify="space-between">
              {isStockLimitReached ? (
                <div>
                  <b>
                    {t('products:Total')}
                    :
                  </b>
                  {` ${totalQuantity} ${t(`products:${salesUnit}`, { count: Number(totalQuantity) })}`}
                </div>
              ) : (
                <>
                  <div>
                    <PriceRevealToolTip
                      value={productPriceDiscounted}
                      supplier={supplier}
                      isFreeDelivery={hasFreeDelivery}
                    />
                    {`${formatPriceNumber(
                      weightedPriceDiscounted,
                    )} ${currency}`}
                    {' '}
                    &times;
                    {' '}
                    {totalQuantity}
                    {' '}
                    {t(`products:${salesUnit}`, { count: totalQuantity })}
                  </div>
                  <div>
                    <h6>
                      {`${formatPriceNumber(
                        totalWeightedPriceDiscounted,
                      )} ${currency}`}
                    </h6>
                  </div>
                </>
              )}
            </FlexboxGrid>
          )}

          {!isQuantityUnderMoq && discountType && !isStockLimitReached && (
            <FlexboxGrid justify="space-between">
              <FlexboxGrid.Item>
                {discountType === 'bulk' && t('products:Bulk & consumption based discounts')}
                {discountType === 'consumption' && t('products:Consumption based discount')}
              </FlexboxGrid.Item>
              <FlexboxGrid.Item>
                {`- ${totalDiscount} ${currency}`}
              </FlexboxGrid.Item>
            </FlexboxGrid>
          )}
        </FlexboxGrid.Item>
      </FlexboxGrid>
      {shouldShowWarning && (
        <div className="margin-top-spacer-double">
          <SupplierMovWarning
            currency={currency}
            mov={mov}
            remainingMov={remainingMov}
            supplierName={currentSupplier?.supplierInfo?.['supplier.label'] || ''}
            supplierCode={currentSupplier?.supplierCode || ''}
          />
        </div>
      )}
    </div>
  )
}

export default ProductOrderInfo
